var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    "product-container",
    {
      'product-container-hot': _vm.product.tag === _vm.ProductTags.BEST_TAG,
      'product-container-discount': _vm.product.tag === _vm.ProductTags.DISCOUNT_TAG,
    } ],on:{"click":_vm.handleClick}},[_c('div',{staticClass:"product-inner-container"},[_c('div',{staticClass:"coin-info"},[_c('div',{staticClass:"coin-icon"},[_c('img',{attrs:{"src":require("@/assets/img/coin.svg"),"alt":"coin"}})]),_c('div',{staticClass:"coin-amount"},[_c('div',{staticClass:"amount"},[_vm._v(_vm._s(_vm.productInfo.coin_amount))]),(_vm.product.tag === _vm.ProductTags.BEST_TAG)?_c('div',{staticClass:"hot-badge"},[_c('div',{staticClass:"hot-img"},[_c('img',{attrs:{"src":require("@/assets/img/fire-icon-purchase.svg"),"alt":"fire-icon"}})]),_c('div',{staticClass:"hot-text"},[_vm._v("Hot")])]):_vm._e(),(_vm.product.tag === _vm.ProductTags.DISCOUNT_TAG)?_c('div',{staticClass:"discount-badge"},[(
              _vm.product.payment_types[1].payment_price.coin_increase_percentage
            )?_c('div',{staticClass:"discount-text"},[_vm._v(" +% "+_vm._s(_vm.product.payment_types[1].payment_price.coin_increase_percentage)+" ")]):_c('div',{staticClass:"discount-text"},[_vm._v(" % "+_vm._s(_vm.product.payment_types[1].payment_price.discount)+" "+_vm._s(_vm.$t("labels.discount"))+" ")])]):_vm._e()])]),_c('div',[(
          _vm.productInfo.original_price &&
          _vm.productInfo.price !== _vm.productInfo.original_price
        )?_c('div',{staticClass:"original-price"},[_vm._v(" "+_vm._s(_vm.productInfo.currency)+" "+_vm._s(_vm.productInfo.original_price)+" ")]):_vm._e(),_c('div',{class:[
          'coin-price',
          {
            'coin-price-hot': _vm.product.tag === _vm.ProductTags.BEST_TAG,
            'coin-price-discount': _vm.product.tag === _vm.ProductTags.DISCOUNT_TAG,
          } ]},[_vm._v(" "+_vm._s(_vm.productInfo.currency)+" "+_vm._s(_vm.productInfo.price)+" ")])])]),_c('meta',{attrs:{"name":"viewport","content":"width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no"}})])}
var staticRenderFns = []

export { render, staticRenderFns }