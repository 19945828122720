<template>
  <div class="terms-of-service container is-fluid">
    <div class="columns">
      <div class="column">
        <div class="terms-content">
          <h1>TERMS OF SERVICE</h1>
          <h2>1. Special Notices</h2>
          <p>
            This {{ brandName }} User Agreement (this “Agreement”) governs your
            usage of our services, (hereinafter, “Services”) including
            {{ brandName }} App, a video streaming application and social
            network developed by us. You are one party and the other party is
            {{ brandName }} or one of its affiliates (“we” or “{{ brandName }}
            LLC”). For the purposes of this Agreement, you and
            {{ brandName }} will be jointly referred to as the “Parties” and
            respectively as a “Party”.
          </p>
          <p>
            When using the Services, you will be subject to
            {{ brandName }}’s Privacy Policy and you be subject to additional
            guidelines or rules that are posted on the Services or made
            available to you, or applicable to specific services and features
            that are disclosed to you in connection with such services. We may
            also offer certain paid services, which are subject to any
            additional terms or conditions that are disclosed to you in
            connection with such services.
          </p>
          <p>
            By using our Services, or by clicking on "Sign Up" during the
            registration process, you agree to all terms of this Agreement. We,
            at our sole discretion, may revise this Agreement from time to time,
            and the current version will be found at the following link: About
            us > User Agreement. By continuing to avail our Services, you agree
            to be bound by the revised Agreement.
          </p>
          <p>
            You may only use our Service if you are 18 years or older, and if
            you are not subject to statutory age limit to enter into this
            Agreement according to the applicable laws and regulations in your
            country.
          </p>
          <p>
            You shall be solely responsible for the safekeeping of your
            {{ brandName }} account and password. All behaviors and activities
            conducted through your {{ brandName }} account will be deemed as
            your behaviors and activities for which you shall be solely
            responsible.
          </p>
          <p>
            We do not accept payments or do business with individuals or
            entities in Cuba, Iran, North Korea, Sudan, Syria, and the Crimean
            region. If you are from such regions, you may be prohibited from
            using the Services.
          </p>
          <h2>2. Services Content</h2>
          <p>
            Our mission is to give people the power to build community, connect
            the world and share their beautiful moments. To help advance this
            mission with a vision to be a content platform inspiring one billion
            people's lives, we provide the services described below to you:
          </p>
          <p>
            i. User Generated Content. {{ brandName }} allows users to
            distribute streaming live, use services, such as chat, bulletin
            boards, forum postings, voice interactive services, and to
            participate in other activities in which you may create, post,
            transmit, perform, or store content, messages, text, sound, images,
            applications, code or other data or materials on the Services (“User
            Content”). Users of the Services may also overlay music, graphics,
            stickers, virtual items and other features provided by
            {{ brandName }} (“{{ brandName }} Features”) onto this User Content
            and transmit this User Content through the Services. The views
            expressed by other users on the Services (including through use of
            the virtual gifts) do not represent our views or values.
          </p>
          <p>
            ii. Help you discover content, products, and services that may
            interest you. We show you ads, offers, and other sponsored content
            to help you discover content, products, and services that are
            offered by the many businesses and organizations that use
            {{ brandName }}.
          </p>
          <p>
            iii. Combat harmful conduct and protect and support our community.
            We employ dedicated teams around the world and develop advanced
            technical systems to detect misuse of our Services, harmful conduct
            towards others, and situations where we may be able to help support
            or protect our community. If we learn of content or conduct like
            this, we will take appropriate action - for example, offering help,
            removing content, removing or restricting access to certain
            features, disabling an account, or contacting law enforcement.
          </p>
          <p>
            iv. Global access to our Services To operate our global service, we
            need to store and distribute content and data in our data centers
            and systems around the world, including outside your country of
            residence. This infrastructure may be operated or controlled by
            {{ brandName }} and its affiliates.
          </p>
          <h2>3. Account</h2>
          <p>
            In order to open an account, you will be asked to provide us with
            certain information such as an account name and password.
          </p>
          <p>
            You are solely responsible for maintaining the confidentiality of
            your account and password, for restricting access to your computer,
            and for all activities that occur under your account or password.
            Please make sure the information you provide to {{ brandName }}
            upon registration and at all other times is true, accurate, current,
            and complete to the best of your knowledge.
          </p>
          <p>
            We may permit you to register for and log on to the Services via
            certain third party services. The third party’s collection, use and
            disclosure of your information will be subject to that third party
            service’s privacy policy. Further information about how
            {{ brandName }} collects, uses and discloses your personal
            information when you link your {{ brandName }} account and with your
            account on any third party service can be found in our Privacy
            Policy.
          </p>
          <p>
            We reserve the right to disable your user account at any time,
            including if you have failed to comply with any of the provisions of
            these terms, or if activities occur on your account which, in our
            sole discretion, would or might cause damage to or impair the
            Services or infringe or violate any third party rights, or violate
            any applicable laws or regulations.
          </p>
          <p>
            If you no longer want to use our Services again, and would like your
            account deleted, we can take care of this for you. Please contact us
            via support@{{ emailDomain }}, and we will provide you with further
            assistance and guide you through the process. Once you choose to
            delete your account, you will not be able to reactivate your account
            or retrieve any of the content or information you have added.
          </p>
          <h2>4. Privacy</h2>
          <p>
            Your privacy is important to {{ brandName }}. Please see our Privacy
            Policy for information relating to how we collect, use, and disclose
            your personal information, and how you can manage your online
            privacy when you use the Services.
          </p>
          <h2>5. Use of the Services</h2>
          <p>
            Your access to and use of the Services is subject to these terms and
            all applicable laws and regulations. You agree that you will comply
            with these terms of Services and {{ brandName }}’s Community
            Guidelines and will not:
          </p>
          <p>
            i. create, upload, transmit, distribute, or store any content that
            is inaccurate, unlawful, infringing, defamatory, obscene,
            pornographic, invasive of privacy or publicity rights, harassing,
            threatening, abusive, inflammatory, or otherwise objectionable;
          </p>
          <p>
            ii. impersonate any person or entity, falsely claim an affiliation
            with any person or entity, or access {{ brandName }} accounts of
            others without permission, forge another person’s identity, or
            content of information transmitted via the Services, or perform any
            other similar fraudulent activity;
          </p>
          <p>
            iii. defame, harass, abuse, threaten or defraud users of
            {{ brandName }}, or collect, or attempt to collect, personal
            information about users or third parties without their consent;
          </p>
          <p>
            iv. remove, circumvent, disable, damage or otherwise interfere with
            security-related features of the the Services or User Content,
            features that prevent or restrict use or copying of any content
            accessible through the Services, features that enforce limitations
            on the use of the Services or User Content, or delete the copyright
            or other proprietary rights notices on the Services or User Content;
          </p>
          <p>
            v. reverse engineer, decompile, disassemble or otherwise attempt to
            discover the source code of the Services or any part thereof, except
            and only to the extent that this activity is expressly permitted by
            the law of your jurisdiction of residence;
          </p>
          <p>
            vi. modify, adapt, translate or create derivative works based upon
            the Services or any part thereof, except and only to the extent that
            such activity is expressly permitted by applicable law
            notwithstanding this limitation;
          </p>
          <p>
            vii. Interfere with or damage operation of the Services or any
            user’s enjoyment of them, by any means, including uploading or
            otherwise disseminating viruses, adware, spyware, worms, or other
            malicious code;
          </p>
          <p>
            viii. manipulate identifiers in order to disguise the origin of any
            User Content transmitted
          </p>
          <p>
            ix. interfere with or disrupt the Services or servers or networks
            connected to the Services, or disobey any requirements, procedures,
            policies or regulations of networks connected to the Services; use
            the Services in any manner that could interfere with, disrupt,
            negatively affect or inhibit other users from fully enjoying the
            Services, or that could damage, disable, overburden or impair the
            functioning of the Services in any manner;
          </p>
          <p>
            x. attempt to circumvent any content filtering techniques we employ,
            or attempt to access any service or area of the Services that you
            are not authorized to access;
          </p>
          <p>
            xi. use the Services for any illegal purpose, or in violation of any
            local, state, national, or international law or regulation,
            including, without limitation, laws governing intellectual property
            and other proprietary rights, data protection and privacy.
          </p>
          <p>
            {{ brandName }} takes no responsibility and assumes no liability for
            any User Content or for any loss or damage resulting therefrom, nor
            is {{ brandName }} liable for any mistakes, defamation, slander,
            libel, omissions, falsehoods, obscenity, pornography or profanity
            you may encounter when using the Services. Your use of the Services
            is at your own risk. In addition, these rules do not create any
            private right of action on the part of any third party or any
            reasonable expectation that the Services will not contain any
            content that is prohibited by such rules.
          </p>
          <p>
            {{ brandName }} is not liable for any statements or representations
            included in User Content. {{ brandName }} does not endorse any User
            Content, opinion, recommendation, or advice expressed therein, and
            {{ brandName }} expressly disclaims any and all liability in
            connection with User Content. To the fullest extent permitted by
            applicable law, {{ brandName }} reserves the right to remove, screen
            or edit any User Content posted or stored on the Services at any
            time and without notice, including where such User Content violates
            these terms of Use of the Services or applicable law, and you are
            solely responsible for creating backup copies of and replacing any
            User Content you post or store on the Services at your sole cost and
            expense. Any use of the Services in violation of the foregoing
            violates these terms of Use of the Services and may result in, among
            other things, termination or suspension of your rights to use the
            Services.
          </p>
          <h2>6. Virtual Items</h2>
          <p>
            You can only buy virtual diamonds (“Diamonds”) and virtual gifts
            (Gifts), send Gifts to others, receive Gifts with monetary value,
            earn virtual beans (“Coins”) and withdraw Coins if you are aged 18
            (or age of majority in your jurisdiction) or older.
          </p>
          <p>
            The price of the Diamonds will be displayed at the point of
            purchase. All charges and payments for Diamonds will be made in the
            currency specified at the point of purchase through the relevant
            payment mechanism. Currency exchange settlements, foreign
            transaction fees and payment channel fees, if any, are based on your
            agreement with the applicable payment provider.
          </p>
          <p>
            You will be responsible for the payment of any Diamonds purchased by
            you. Once your purchase has been completed, your user account will
            be credited with Diamonds. Diamonds can be used to purchase Gifts.
            Diamonds cannot be exchanged for cash, or legal tender, or currency
            of any state, region, or any political entity, or any other form of
            credit. Diamonds can only be used on {{ brandName }} and as part of
            our Services, and cannot be combined or used in conjunction with
            other promotions, coupons, discounts or special offers, except those
            designated by us.
          </p>
          <p>
            Except as otherwise set out in this Agreement, all sales of Diamonds
            and Gifts are final, and we do not offer refunds for any purchased
            Diamonds and Gifts. If necessary excuses are evaluated and accepted
            by the support department, refund can be done. Diamonds and Gifts
            cannot be converted into or exchanged for cash, or be refunded or
            reimbursed by us for any reason.
          </p>
          <h2>7. Payment Terms</h2>
          <p>
            We accept major credit cards, certain debit cards and/or such other
            payment methods we may make available to you from time-to-time
            through our site, as forms of payment. You are subject to all terms
            and conditions of the payment method you choose. By submitting an
            order through the Service, you authorize us, or our designated
            payment processor, to charge the account you specify for the
            purchase amount. All payments are to be made in United States
            Dollars, except where other currencies are offered via the payment
            methods made available by {{ brandName }}.
          </p>
          <p>
            Our payment processing partners may request that you provide certain
            personal data (e.g., a valid government issued ID, your legal name,
            address, and date of birth) for the purpose of making payment
            through its financial institutions and complying with applicable
            international, national, federal, state, and local laws and
            regulations. They may also communicate directly with you regarding
            any issues with a payment.
          </p>
          <p>
            If a purchase has been declined online due to issues with your
            payment method, please ensure all data is correct and resubmit. If
            the transaction is not accepted online, please contact customer
            support via the email address made available to you in connection
            with the purchase. For purchases on the Service, you may contact
            support@{{ emailDomain }}
          </p>
          <h2>8. Intellectual Property Rights</h2>
          <p>
            All text, data, images, graphics, audio and/or video information and
            other materials within the Services provided by {{ brandName }} are
            property of {{ brandName }} are protected by copyright, trademark
            and/or other property rights laws. Nothing in this Agreement shall
            be construed as conferring any license of any intellectual property
            rights or such materials by {{ brandName }} to you.
          </p>
          <p>
            By using and/or uploading any live stream content or other content
            through a {{ brandName }} Services to publicly accessible areas of
            {{ brandName }} website, you grant to {{ brandName }} and its
            sub-licensees the permission, free, permanent, irrevocable,
            non-exclusive and fully sub-licensable rights and license, without
            any territorial or time limitations and without requiring any
            approvals and/or compensations, to use, copy, modify, adapt,
            publish, translate, edit, dispose, create derivate works of,
            distribute, perform and publicly display such content (in whole or
            in part), and/or incorporate such content into existing or future
            forms of work, media or technology.
          </p>
          <h2>9. Terminating Services</h2>
          <p>
            To the fullest extent permitted by applicable law,
            {{ brandName }} reserves the right, without notice and in our sole
            discretion, to terminate your license to use the Services (including
            to post User Content), and to block or prevent your future access to
            and use of the Services, including where we reasonably consider
            that: (a) your use of the Services violates this Agreement or
            applicable law; (b) you fraudulently use or misuse the Services; or
            (c) we are unable to continue providing the Services to you due to
            technical or legitimate business reasons. This includes the ability
            to terminate or to suspend your access to any purchased products or
            services. To the fullest extent permitted by applicable law, your
            only remedy with respect to any dissatisfaction with (i) the
            Services, (ii) any term of these terms of Services, (iii) any policy
            or practice of {{ brandName }} in operating the Services, or (iv)
            any content or information transmitted through the Services, is to
            terminate your account and to discontinue use of any and all parts
            of the Services.
          </p>
          <h2>10. Disclaimers</h2>
          <p>
            You shall be fully responsible for any risks involved in using
            {{ brandName }} Services. Any use or reliance on
            {{ brandName }} Services will be at your own risk.
          </p>
          <p>
            Under no circumstance does {{ brandName }} guarantee that the
            Services will satisfy your requirements, or guarantee that the
            Services will be uninterrupted. The timeliness, security and
            accuracy of the Services are also not guaranteed. You acknowledge
            and agree that the Services is provided by {{ brandName }} on an “as
            is” basis. {{ brandName }} make no representations or warranties of
            any kind express or implied as to the operation and the providing of
            such Services or any part thereof. {{ brandName }} shall not be
            liable in any way for the quality, timeliness, accuracy or
            completeness of the Services and shall not be responsible for any
            consequences which may arise from your use of such Services.
          </p>
          <p>
            {{ brandName }} does not guarantee the accuracy and integrity of any
            external links that may be accessible by using the Services and/or
            any external links that have been placed for the convenience of you.
            {{ brandName }} shall not be responsible for the content of any
            linked site or any link contained in a linked site, and
            {{ brandName }} shall not be held responsible or liable, directly or
            indirectly, for any loss or damage in connection with the use of the
            Services by you. Moreover, {{ brandName }} shall not bear any
            responsibility for the content of any webpage that you are directed
            via an external link that is not under the control of
            {{ brandName }}.
          </p>
          <p>
            {{ brandName }} shall not bear any liability for the interruption of
            or other inadequacies in the Services caused by circumstances of
            force majeure, or that are otherwise beyond the control of
            {{ brandName }}. However, as far as possible, {{ brandName }} shall
            reasonably attempt to minimize the resulting losses of and impact
            upon you.
          </p>
          <h2>11. Request for information</h2>
          <p>
            All requests for information or documents related to potential,
            anticipated or current legal proceedings, investigations or
            disputes, or for third party user information, from any
            {{ brandName }} Services must be made using the appropriate level of
            legal process, and must be properly served on {{ brandName }} via
            {{ brandTitle }}
            LLC.
          </p>
          <h2>12. Modification of the Agreement</h2>
          <p>
            We amend these terms of the Agreement from time to time, for
            instance when we update the functionality of our Services, when we
            combine multiple apps or services operated by us or our affiliates
            into a single combined service or app, or when there are regulatory
            changes. We will use commercially reasonable efforts to generally
            notify all users of any material changes to these terms, such as
            through a notice on {{ brandName }} platform. However, you should
            look at the Agreement regularly to check for such changes. Your
            continued access or use of the Services after the date of the new
            terms constitutes your acceptance of the new terms. If you do not
            agree to the new terms, you must stop accessing or using the
            Services.
          </p>
          <h2>13. Other Terms</h2>
          <p>
            This Agreement constitutes the entire agreement of agreed items and
            other relevant matters between both parties. Other than as
            stipulated by this Agreement, no other rights are vested in either
            Party to this Agreement.
          </p>
          <p>
            If any provision of this Agreement is rendered void or unenforceable
            by competent authorities, in whole or in part, for any reason, the
            remaining provisions of this Agreement shall remain valid and
            binding.
          </p>
          <p>
            The headings within this Agreement have been set for the sake of
            convenience, and shall be disregarded in the interpretation of this
            Agreement.
          </p>
          <p>
            {{ companyAddressTitle }}
          </p>
          <p>
            {{ companyAddress }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.terms-of-service {
  text-align: left;
  color: $gray-darkest;
  .terms-content {
    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      color: $primary;
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      margin-top: 15px;
      color: $primary;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
      margin-top: 15px;
      color: $primary;
    }
    ul {
      padding-left: 10px;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
</style>
<script>
import { mapState } from "vuex";
export default {
  name: "TermsOfService",
  computed: {
    ...mapState({
      brandName: (state) => {
        const brandName = state.brandName;
        if (brandName == "red") {
          return "MOBITECH MARKETING SOFTWARE LIMITED";
        } else if (brandName == "liveflamingo") {
          return "Mobitech Marketing Software Limited";
        } else {
          return brandName + " LLC";
        }
      },
      brandTitle: (state) => state.brandTitle,
      emailDomain: (state) => state.emailDomain,
      companyAddressTitle: (state) => {
        const brandName = state.brandName;
        // if (brandName == "superlive") {
        //   return "Happymob Software Limited";
        // } else {
        //   return "";
        // }
        return "";
      },
      companyAddress: (state) => {
        const brandName = state.brandName;
        // if (brandName == "superlive") {
        //   return "112 Princes Gardens, London, UK W3 0LJ";
        // } else {
        //   return "";
        // }
        return "";
      },
    }),
  },
};
</script>
