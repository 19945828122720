<template>
  <div class="container">
    <div class="top-container" v-if="purchaseSettings">
      <div class="top-left">
        <div class="back-button" @click="closeHybridPage">
          <img
            :src="require(`@/assets/img/back-button_superlive.svg`)"
            alt="back"
          />
        </div>
        <div :class="[`header-text`, { 'header-text-ar': language === `ar` }]">
          {{ $t("labels.do_payment") }}
        </div>
      </div>
      <div class="top-right">
        <div
          class="country-select"
          v-if="purchaseSettings.is_payment_country_change_active"
          @click="toggleSelectCountry"
        >
          <div class="img-container">
            <img
              :src="
                require(`@/assets/img/flags/${countryCodeToFlagName(
                  purchaseSettings.country
                )}.png`)
              "
              alt="country-flag"
            />
          </div>
          <i class="fas fa-chevron-right"></i>
        </div>
        <div class="coins">
          <div class="coin-icon">
            <img :src="require(`@/assets/img/coin.svg`)" alt="coin" />
          </div>
          <div class="coin-amount">
            {{ numberWithCommas(userCoinInfo.coins) }}
          </div>
        </div>
      </div>
    </div>
    <div class="banners" v-if="promotionBanners.length > 0">
      <v-card class="rounded-card">
        <v-carousel
          cycle
          height="120"
          hide-delimiter-background
          interval="4000"
        >
          <v-carousel-item
            v-for="(promotionBanner, index) in promotionBanners"
            :key="`promotion-banner-${index}`"
            :src="promotionBanner.image_url"
            @click="goToPromotionBanner(index)"
          >
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </div>
    <div v-if="products != null" class="products">
      <CoinProduct
        v-for="(product, index) in products"
        :key="index"
        :product="product"
        @productSelected="selectProduct(product)"
      />
    </div>

    <v-dialog
      v-model="showSelectCountry"
      v-if="
        paymentCountries && purchaseSettings.is_payment_country_change_active
      "
      transition="dialog-bottom-transition"
    >
      <v-card class="select-country-card">
        <div class="select-country-container">
          <div class="country-container-header my-2">
            <span class="header-text">
              {{ $t("payermax.select_country") }}
            </span>
            <button class="close-button" @click="toggleSelectCountry()">
              <img
                width="15"
                height="15"
                src="@/assets/img/cancel-gray.svg"
                alt="cancel"
              />
            </button>
          </div>
          <div class="search-input-container">
            <img src="@/assets/img/search-icon.svg" alt="search-icon" />
            <input
              type="text"
              class="search-input ml-2"
              :placeholder="$t('payermax.search_country')"
              v-model="searchCountryText"
              autocorrect="off"
              autocomplete="off"
            />
          </div>
          <div class="mt-2 country-item-container">
            <div
              class="country-item-card"
              v-for="(country, countryIndex) in filteredCountries"
              :key="countryIndex"
            >
              <div
                :class="[
                  `country-item`,
                  {
                    'country-item-selected':
                      country &&
                      purchaseSettings &&
                      purchaseSettings.country === country.iso_code,
                  },
                ]"
                @click="selectCountry(country.iso_code)"
              >
                <img
                  :src="
                    require(`@/assets/img/flags/${countryCodeToFlagName(
                      country.iso_code
                    )}.png`)
                  "
                  alt="country-flag"
                  class="country-flag"
                />
                <span class="country-name">
                  {{ capitalizeFirstLetters(country.name) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <PaymentMethodBottomSheet
      v-if="purchaseSettings"
      v-show="selectedProduct !== null"
      ref="paymentMethodBottomSheet"
      :product="selectedProduct"
      :selected-offer-index="1"
      :privacy-policy-link="purchaseSettings.urls.privacy_policy"
      :terms-of-service-link="purchaseSettings.urls.terms_of_service"
      :support-user-id="purchaseSettings.support_user_id"
    />
    <v-dialog
      :value="showPromotionExpiredPopUp"
      :transition="false"
      :animation="false"
    >
      <v-card class="promotion-expired-wrapper">
        <div class="promotion-expired-container">
          <div>
            <img
              src="@/assets/img/ic-offer.svg"
              :alt="$t('payermax.promotion_outdated_offer_popup_title')"
            />
          </div>
          <div class="promotion-header">
            {{ $t("payermax.promotion_outdated_offer_popup_title") }}
          </div>
          <div class="promotion-info">
            {{ $t("payermax.promotion_outdated_offer_popup_description") }}
          </div>
          <button class="promotion-button" @click="closePromotionExpired">
            {{ $t("labels.ok") }}
          </button>
        </div>
      </v-card>
    </v-dialog>
    <v-overlay color="#fff" :value="showFullPageLoading">
      <v-progress-circular
        color="#333"
        :size="50"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { HybridStates, PaymentProvider } from "@/helper/enums";
import CoinProduct from "@/components/Payment/Payermax/CoinProduct.vue";
import { countryCodeToFlagName } from "@/helper/countryCodeToFlagName";
import { localStore, STORE_KEYS } from "@/storage";
import PaymentMethodBottomSheet from "@/components/Payment/Payermax/PaymentMethodBottomSheet.vue";
import { Logger } from "@/logging";

const logger = new Logger("CoinSelectPayermax");
export default {
  name: "CoinSelectPayermax",
  data() {
    return {
      PaymentProvider,
      HybridStates,
      language: window.TheURLSearchParams.get("lang"),
      promotionBanners: [],
      paymentCountries: null,
      purchaseSettings: null,
      products: null,
      showSelectCountry: false,
      searchCountryText: null,
      selectedProduct: null,
      showPromotionExpiredPopUp: false,
      showFullPageLoading: false,
    };
  },
  components: { PaymentMethodBottomSheet, CoinProduct },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      currencies: (state) => state.client.currencies,
      userCoinInfo: (state) => state.client.userCoinInfo,
    }),
    filteredCountries: function () {
      if (this.searchCountryText) {
        return this.paymentCountries.filter((country) =>
          country.name
            .toLowerCase()
            .includes(this.searchCountryText.toLowerCase())
        );
      } else {
        return this.paymentCountries;
      }
    },
  },
  watch: {
    userCoinInfo() {
      if (
        this.userCoinInfo.multi_payment_coin_offer &&
        this.userCoinInfo.multi_payment_coin_offer.products
      ) {
        this.products = this.userCoinInfo.multi_payment_coin_offer.products;
      }
    },
  },
  mounted() {
    const platform = this.$route.query.platform;
    this.setPlatform(platform != null ? platform : null);

    this.bannerId = this.$route.query.banner_id;
    if (this.bannerId) {
      this.showPromotionExpiredPopUp = true;
    }

    this.getPromotionBanners();
    this.getPurchaseSettings().then((success) => {
      if (success) {
        this.$hybridapi("loadSuccess", null);
        this.$hybridapi("hideLoading");
        this.$hybridapi("updatePageTitle", "");
        this.$hybridapi("updateTopBar", "hide", null, null, null);
        this.$hybridapi("hideBackButton", "hide");

        this.nativeLoadingRestoreValue = false;
      }
    });
  },
  methods: {
    countryCodeToFlagName,
    ...mapActions(["setPlatform"]),
    ...mapActions("payment", [
      "getActivePromotionBanners",
      "payermaxInappPurchaseSettings",
      "payermaxSelectCountry",
      "payermaxPaymentCountries",
      "coinUpdateWebsocket",
    ]),
    listenToCoinUpdateEvents() {
      this.coinUpdateWebsocket(this.$hybrid_websocket_url);
    },
    getPromotionBanners() {
      this.getActivePromotionBanners().then((promotionBannersResponse) => {
        if (promotionBannersResponse.error) {
          logger.debugError("error");
        } else {
          this.promotionBanners =
            promotionBannersResponse.active_promotion_banners;
        }
      });
    },
    langSplitter() {
      if (this.language === "ar" || this.language === "en") {
        return ",";
      } else if (this.language === "ru") {
        return " ";
      } else {
        return ".";
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.langSplitter());
    },
    goToPromotionBanner(index) {
      let redirectUrl = "https://liveapp-prod.web.app/";
      let authToken = localStore.getItem(STORE_KEYS.AUTH_TOKEN);
      let deviceId = localStore.getItem(STORE_KEYS.DEVICE_ID);
      let bdi = window.TheURLSearchParams.get("bdi");
      let app = window.TheURLSearchParams.get("app_build");
      let platform = window.TheURLSearchParams.get("platform");
      let lang = window.TheURLSearchParams.get("lang");
      let bannerId = this.promotionBanners[index].banner_id;

      if (lang === "fil") {
        lang = "en";
      }

      if (
        location.host.startsWith("localhost") ||
        location.host.startsWith("192.168")
      ) {
        redirectUrl = "http://localhost:8004/";
      } else if (location.host === "web-habibipurchase.liveapp-dev.com") {
        redirectUrl = "https://hybrid-habibipurchase.liveapp-dev.com/";
      } else if (location.host === "superlive-staging.web.app") {
        redirectUrl = "https://liveapp-staging.web.app/";
      }
      redirectUrl = redirectUrl.concat(
        "?app_build=",
        app,
        "&platform=",
        platform,
        "&device_id=",
        deviceId,
        "&auth_token=",
        authToken,
        "&bdi=",
        bdi,
        "&lang=",
        lang
      );
      window.location.href = redirectUrl.concat("#/banner/", bannerId);
    },
    getPurchaseSettings() {
      return this.payermaxInappPurchaseSettings()
        .then((purchaseSettingsResponse) => {
          if (purchaseSettingsResponse.error) {
            // Error response from server
            logger.debugError(
              "payermaxInappPurchaseSettings error",
              purchaseSettingsResponse.error
            );
            this.$toast.error(
              purchaseSettingsResponse.error.message ||
                this.$t("errors.general_error")
            );
            return false;
          } else {
            // Success response from server
            this.purchaseSettings = purchaseSettingsResponse;
            if (purchaseSettingsResponse) {
              this.listenToCoinUpdateEvents();
              this.$store.dispatch(
                "client/setUserCoinInfo",
                this.purchaseSettings.coin_info
              );
            }
            return true;
          }
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("payermaxInappPurchaseSettings failed", error);
          this.$toast.error(this.$t("errors.general_error"));
          return false;
        });
    },
    getPaymentCountries() {
      return this.payermaxPaymentCountries()
        .then((paymentCountriesResponse) => {
          if (paymentCountriesResponse.error) {
            // Error response from server
            logger.debugError(
              "payermaxPaymentCountries error",
              paymentCountriesResponse.error
            );
            return false;
          } else {
            // Success response from server
            let paymentCountriesTemp = [];
            paymentCountriesResponse.items.forEach((countriesItem) =>
              countriesItem.countries.forEach((countryItem) => {
                paymentCountriesTemp.push(countryItem);
              })
            );
            paymentCountriesTemp.sort((a, b) => a.name.localeCompare(b.name));
            this.paymentCountries = paymentCountriesTemp;
            return true;
          }
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("payermaxPaymentCountries failed", error);
          return false;
        });
    },
    toggleSelectCountry() {
      this.searchCountryText = null;

      if (this.paymentCountries == null) {
        this.showFullPageLoading = true;
        this.getPaymentCountries()
          .then((success) => {
            if (success) {
              this.showSelectCountry = true;
            } else {
              this.$toast.error(this.$t("errors.general_error"));
            }
          })
          .finally(() => {
            this.showFullPageLoading = false;
          });
      } else {
        this.showSelectCountry = !this.showSelectCountry;
      }
    },
    selectCountry(country) {
      this.showFullPageLoading = true;
      this.showSelectCountry = false;
      this.payermaxSelectCountry(country)
        .then((selectCountryResponse) => {
          this.showFullPageLoading = false;
          if (selectCountryResponse.error) {
            // Error response from server
            logger.debugError(
              "payermaxSelectCountry error",
              selectCountryResponse.error
            );
            this.$toast.error(
              selectCountryResponse.error.message ||
                this.$t("errors.general_error")
            );
            this.showSelectCountry = true;
          } else {
            // Success response from server
            this.purchaseSettings = selectCountryResponse;
            this.$store.dispatch(
              "client/setUserCoinInfo",
              selectCountryResponse.coin_info
            );
          }
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("payermaxSelectCountry failed", error);
          this.$toast.error(this.$t("errors.general_error"));
          this.showFullPageLoading = false;
          this.showSelectCountry = true;
        });
    },
    capitalizeFirstLetters(string) {
      const language = window.TheURLSearchParams.get("lang");
      if (string) {
        let changedString = string.toLocaleLowerCase(language);
        let newStringArray = [];
        changedString.split(" ").forEach((splittedItem) => {
          newStringArray.push(
            splittedItem.charAt(0).toLocaleUpperCase(language) +
              splittedItem.substring(1)
          );
        });
        return newStringArray.join(" ");
      }
      return "";
    },
    closeHybridPage() {
      this.hybridClose("close");
    },
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
    open() {
      this.$refs.paymentMethodBottomSheet.open();
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.$nextTick(() => {
        this.open();
      });
    },
    closePromotionExpired() {
      this.showPromotionExpiredPopUp = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/components/Payment/Payermax/CoinSelectPayermax.scss";
</style>
