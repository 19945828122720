<template>
  <div>
    <vue-bottom-sheet-vue2
      custom-class="payment-method-bottom-sheet"
      ref="paymentMethodBottomSheet"
      :z-index="1000"
    >
      <template #header>
        <div class="header">{{ $t("payermax.choose_payment_method") }}</div>
      </template>
      <template #default>
        <div class="main-container">
          <div
            v-for="(paymentType, index) in paymentTypes"
            :key="index"
            :class="[
              `product-container`,
              {
                'product-container-selected':
                  selectedPayment !== null &&
                  paymentType.name === selectedPayment.name,
              },
            ]"
            @click="selectPaymentMethod(paymentType)"
          >
            <div class="product-inner-container">
              <div class="payment-type">
                <div class="payment-type-icon">
                  <img :src="paymentType.icon_url" alt="payment-type-icon" />
                </div>
                <div class="name-container">
                  <div
                    v-if="
                      paymentType.payment_price !== null &&
                      (paymentType.payment_price.discount ||
                        paymentType.payment_price.coin_increase_percentage)
                    "
                    class="discount-badge"
                  >
                    <div
                      v-if="paymentType.payment_price.coin_increase_percentage"
                      class="discount-text"
                    >
                      +%
                      {{ paymentType.payment_price.coin_increase_percentage }}
                    </div>
                    <div v-else class="discount-text">
                      % {{ paymentType.payment_price.discount }}
                      {{ $t("labels.discount") }}
                    </div>
                  </div>
                  <div class="name">{{ paymentType.name }}</div>
                </div>
              </div>
              <div
                v-if="paymentType.payment_provider !== PaymentProvider.DEALER"
                class="coin-info"
              >
                <div class="coin-icon">
                  <img :src="require(`@/assets/img/coin.svg`)" alt="coin" />
                </div>
                <div class="coin-amount">
                  <div
                    v-if="
                      paymentType.payment_price !== null &&
                      paymentType.payment_price.overriden_coin_amount
                    "
                  >
                    <div v-if="showCoinIncrease" class="initial-coin-amount">
                      {{
                        calculateInitialCoinAmount(
                          paymentType.payment_price.overriden_coin_amount,
                          paymentType.payment_price.coin_increase_percentage
                        )
                      }}
                    </div>
                    <div class="amount">
                      {{ paymentType.payment_price.overriden_coin_amount }}
                    </div>
                  </div>
                  <div v-else class="amount">
                    {{ product.amount }}
                  </div>
                </div>
              </div>
              <div v-else class="dealer-button">
                <img :src="require('@/assets/img/greater-icon.svg')" />
              </div>
            </div>
          </div>
        </div>
        <div class="pay-button-container">
          <div class="pay-button" @click="proceedPayment(selectedPayment)">
            <div
              v-if="
                selectedPayment !== null &&
                selectedPayment.payment_provider !== PaymentProvider.DEALER
              "
            >
              {{ currency }}
              {{ selectedPayment.payment_price.price }}
              {{ $t("labels.do_payment") }}
            </div>
          </div>
        </div>
        <div class="payment-method-footer">
          <button class="customer-service" @click="openCustomerService">
            <img
              src="@/assets/img/headphone-icon-new.svg"
              alt="Customer Service"
            />
            <span>{{ $t("payermax.customer_service") }}</span>
          </button>
          <div class="privacy-link">
            <span class="link" @click="openLink(privacyPolicyLink)">{{
              $t("menu.terms_of_service")
            }}</span>
            <span class="link-seperator">&</span>
            <span class="link" @click="openLink(termsOfServiceLink)">{{
              $t("menu.privacy_policy")
            }}</span>
          </div>
        </div>
        <v-overlay color="#fff" :value="showFullPageLoading">
          <v-progress-circular
            color="#333"
            :size="50"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </template>
    </vue-bottom-sheet-vue2>
    <SavedCardsBottomSheet
      ref="savedCardsBottomSheet"
      v-if="savedCardsData !== null"
      :data="savedCardsData"
      :show-full-page-loading="savedCardLoading"
      :show-save-card-permission="
        selectedPayment.payment_provider === PaymentProvider.TRUSTPAY
      "
      :show-add-card="
        (selectedPayment.payment_provider === PaymentProvider.TRUSTPAY &&
          savedCardsData.cards.length < 5) ||
        selectedPayment.payment_provider === PaymentProvider.PAYERMAX
      "
      @add-card="onAddCard"
      @delete-card="onDeleteCard"
      @pay="onSavedCardPay"
    />
  </div>
</template>

<script>
import { Logger } from "@/logging";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import { PaymentProvider, PaymentOperationStatus } from "@/helper/enums";
import { mapActions, mapState } from "vuex";
import { generatePaymentUUID, getPaymentUUID } from "@/helper";
import SavedCardsBottomSheet from "@/components/Payment/SavedCard/SavedCardsBottomSheet.vue";
const logger = new Logger("PaymentMethodBottomSheet");
export default {
  name: "PaymentMethodBottomSheet",
  data() {
    return {
      PaymentProvider,
      paymentTypes: null,
      selectedPayment: null,
      showFullPageLoading: false,
      isIOS: false,
      savedCardsData: null,
      savedCardLoading: false,
    };
  },
  components: { SavedCardsBottomSheet, VueBottomSheetVue2 },
  props: [
    "product",
    "selectedOfferIndex",
    "privacyPolicyLink",
    "termsOfServiceLink",
    "supportUserId",
  ],
  watch: {
    selectedPayment: async function (payment) {
      if (payment == null || !payment.payable_with_saved_card) {
        this.savedCardsData = null;
        return;
      }

      const savedCardsBottomSheetData = {
        currency: this.currency,
        price: payment.payment_price.price,
      };

      if (payment.payment_price.overriden_coin_amount != null) {
        savedCardsBottomSheetData["coinAmount"] =
          payment.payment_price.overriden_coin_amount;
      } else {
        savedCardsBottomSheetData["coinAmount"] = this.product.amount;
      }

      if (payment.payment_provider === PaymentProvider.PAYERMAX) {
        const getSavedCardsResponse = await this.getSavedCards({
          provider: PaymentProvider.PAYERMAX,
        });
        savedCardsBottomSheetData["cards"] = getSavedCardsResponse.cards;
        this.savedCardsData = savedCardsBottomSheetData;
        return;
      }

      if (payment.payment_provider === PaymentProvider.TRUSTPAY) {
        const getSavedCardsResponse = await this.getSavedCards({
          provider: PaymentProvider.TRUSTPAY,
        });
        savedCardsBottomSheetData["cards"] = getSavedCardsResponse.cards;
        this.savedCardsData = savedCardsBottomSheetData;
        return;
      }
    },
  },
  computed: {
    ...mapState({
      currencies: (state) => state.client.currencies,
    }),
    currency: function () {
      if (this.selectedPayment === null) {
        return null;
      }

      if (this.selectedPayment.payment_price === null) {
        return null;
      }

      const currency =
        this.currencies[
          this.selectedPayment.payment_price.currency.toUpperCase()
        ];
      if (currency != null) {
        return currency;
      }

      return this.selectedPayment.payment_price.currency.toUpperCase();
    },
    price: function () {
      if (this.selectedPayment === null) {
        return null;
      }

      if (this.selectedPayment.payment_price === null) {
        return null;
      }

      return this.selectedPayment.payment_price.price;
    },
    showCoinIncrease: function () {
      let is_google_or_apple_exists = false;
      let price_discount_mode = false;
      this.paymentTypes.forEach((paymentType) => {
        if (
          paymentType.payment_provider === PaymentProvider.APPLE ||
          paymentType.payment_provider === PaymentProvider.GOOGLE
        ) {
          is_google_or_apple_exists = true;
        } else if (
          paymentType.payment_provider === PaymentProvider.PAYERMAX ||
          paymentType.payment_provider === PaymentProvider.RAPYD ||
          paymentType.payment_provider === PaymentProvider.TRUSTPAY
        ) {
          price_discount_mode =
            paymentType.payment_price.overriden_coin_amount === undefined;
        }
      });
      return !is_google_or_apple_exists && !price_discount_mode;
    },
  },
  mounted() {
    this.isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  },
  methods: {
    ...mapActions("payment", [
      "payermaxPayment",
      "rapydPayment",
      "razerPayment",
      "deleteSavedCard",
      "getSavedCards",
      "trustPayPayment",
    ]),
    openCustomerService() {
      this.$hybridapi("openNativeConversation", this.supportUserId);
    },
    openLink(link) {
      this.$hybridapi("openLinkInBrowser", link);
    },
    open() {
      this.paymentTypes = this.product.payment_types.filter(
        (item) => item.is_available === true
      );
      if (this.paymentTypes.length > 1) {
        if (
          this.paymentTypes[0].payment_provider === PaymentProvider.PAYERMAX ||
          this.paymentTypes[0].payment_provider === PaymentProvider.RAPYD ||
          this.paymentTypes[0].payment_provider === PaymentProvider.STRIPE ||
          this.paymentTypes[0].payment_provider === PaymentProvider.TRUSTPAY ||
          this.paymentTypes[0].payment_provider === PaymentProvider.RAZER
        ) {
          this.selectedPayment = this.paymentTypes[0];
        } else if (
          this.paymentTypes[this.selectedOfferIndex].payment_provider ===
          PaymentProvider.DEALER
        ) {
          this.selectedPayment = this.paymentTypes[0];
        } else {
          this.selectedPayment = this.paymentTypes[this.selectedOfferIndex];
        }
      } else {
        this.selectedPayment = this.paymentTypes[0];
      }
      generatePaymentUUID();
      this.$refs.paymentMethodBottomSheet.open();
    },
    selectPaymentMethod(paymentType) {
      if (paymentType.payment_provider === PaymentProvider.DEALER) {
        // PAY TO DEALER
        this.$store.dispatch("payment/setSelectedProduct", this.product);
        this.$hybridapi("showLoading");
        this.$refs.paymentMethodBottomSheet.close();
        this.$router.push("/payermax/coin-reseller");
      } else {
        this.selectedPayment = paymentType;
      }
    },
    async proceedPayment(paymentMethod) {
      if (paymentMethod.is_available) {
        if (paymentMethod.payment_provider === PaymentProvider.PAYERMAX) {
          if (
            this.savedCardsData != null &&
            // Reactive element length is undefined on vue somehow
            Object.keys(this.savedCardsData.cards).length > 0
          ) {
            this.$refs.savedCardsBottomSheet.open();
          } else {
            this.showFullPageLoading = true;
            const isRedirecting = await this.payWithPayermax(paymentMethod);
            if (!isRedirecting) {
              this.showFullPageLoading = false;
            }
          }
        } else if (paymentMethod.payment_provider === PaymentProvider.GOOGLE) {
          // GOOGLE PAYMENT
          this.$hybridapi("triggerPurchase", this.product.sku);
          this.$hybridapi("showLoading");
        } else if (paymentMethod.payment_provider == PaymentProvider.APPLE) {
          // APPLE PAYMENT
          this.$hybridapi("triggerPurchase", this.product.sku);
          this.$hybridapi("showLoading");
        } else if (paymentMethod.payment_provider === PaymentProvider.RAPYD) {
          // RAPYD PAYMENT
          this.showFullPageLoading = true;
          let paymentData = {
            idempotency_key: getPaymentUUID(),
            payment_type: paymentMethod.code,
          };
          if (this.product != null) {
            paymentData["product_id"] = this.product.sku;
          } else {
            this.$toast.error(this.$t("payermax.selected_product_not_found"));
            return;
          }
          await this.rapydPayment(paymentData)
            .then((rapydPaymentResponse) => {
              // Generate new UUID for next attempt on success or error from server.
              generatePaymentUUID();

              if (rapydPaymentResponse.error) {
                // Error response from server
                logger.debugError(
                  "rapydPayment error",
                  rapydPaymentResponse.error
                );
                this.showFullPageLoading = false;
                this.$toast.error(
                  rapydPaymentResponse.error.message ||
                    this.$t("errors.general_error")
                );
              } else if (rapydPaymentResponse.redirect_url) {
                // Success response from server
                if (this.isIOS) {
                  this.showTopBar();
                } else {
                  this.hideTopBar();
                }
                window.location.href = rapydPaymentResponse.redirect_url;
              } else {
                logger.debugError(
                  "rapydPayment unexpected case",
                  rapydPaymentResponse
                );
                this.$toast.error(this.$t("errors.general_error"));
                this.hybridClose("close");
              }
            })
            .catch((error) => {
              // Network error or server fail
              logger.debugError("rapydPayment failed", error);
              this.showFullPageLoading = false;
              this.$toast.error(this.$t("errors.general_error"));
            });
        } else if (paymentMethod.payment_provider === PaymentProvider.RAZER) {
          // RAZER PAYMENT
          this.showFullPageLoading = true;
          let paymentData = {
            idempotency_key: getPaymentUUID(),
            channel_id: parseInt(paymentMethod.code),
          };
          if (this.product != null) {
            paymentData["product_id"] = this.product.sku;
          } else {
            this.$toast.error(this.$t("payermax.selected_product_not_found"));
            return;
          }
          await this.razerPayment(paymentData)
            .then((razerPaymentResponse) => {
              // Generate new UUID for next attempt on success or error from server.
              generatePaymentUUID();

              if (razerPaymentResponse.error) {
                // Error response from server
                logger.debugError(
                  "razerPayment error",
                  razerPaymentResponse.error
                );
                this.showFullPageLoading = false;
                this.$toast.error(
                  razerPaymentResponse.error.message ||
                    this.$t("errors.general_error")
                );
              } else if (razerPaymentResponse.redirect_url) {
                // Success response from server
                if (razerPaymentResponse.open_on_external_browser) {
                  this.showFullPageLoading = false;
                  this.$hybridapi(
                    "openLinkInBrowser",
                    razerPaymentResponse.redirect_url
                  );
                } else {
                  if (this.isIOS) {
                    this.showTopBar();
                  } else {
                    this.hideTopBar();
                  }
                  window.location.href = razerPaymentResponse.redirect_url;
                }
              } else {
                logger.debugError(
                  "razerPayment unexpected case",
                  razerPaymentResponse
                );
                this.$toast.error(this.$t("errors.general_error"));
                this.hybridClose("close");
              }
            })
            .catch((error) => {
              // Network error or server fail
              logger.debugError("razerPayment failed", error);
              this.showFullPageLoading = false;
              this.$toast.error(this.$t("errors.general_error"));
            });
        } else if (
          paymentMethod.payment_provider === PaymentProvider.TRUSTPAY
        ) {
          this.$refs.savedCardsBottomSheet.open();
        }
      }
    },
    async payWithPayermax(paymentMethod, savedCardId = null) {
      // PAYERMAX
      const paymentData = {
        product_id: this.product.sku,
        order_id: getPaymentUUID(),
        payment_type: paymentMethod.code,
        saved_card_id: savedCardId,
      };
      try {
        const payermaxPaymentResponse = await this.payermaxPayment(paymentData);
        // Generate new UUID for next attempt on success or error from server.
        generatePaymentUUID();

        if (payermaxPaymentResponse.error) {
          // Error response from server
          logger.debugError(
            "payermaxPayment error",
            payermaxPaymentResponse.error
          );
          this.$toast.error(
            payermaxPaymentResponse.error.message ||
              this.$t("errors.general_error")
          );
          return false;
        } else if (payermaxPaymentResponse.request_url) {
          // Success response from server
          if (this.isIOS) {
            this.showTopBar();
          } else {
            this.hideTopBar();
          }
          window.location.href = payermaxPaymentResponse.request_url;
          return true;
        } else {
          logger.debugError(
            "payermaxPayment unexpected case",
            payermaxPaymentResponse
          );
          this.$toast.error(this.$t("errors.general_error"));
          this.hybridClose("close");
          return false;
        }
      } catch (error) {
        // Network error or server fail
        logger.debugError("payermaxPayment failed", error);
        this.$toast.error(this.$t("errors.general_error"));
        return false;
      }
    },
    async payWithTrustpay(savedCardId, saveCardPermission) {
      let paymentData = {
        idempotency_key: getPaymentUUID(),
      };
      if (this.product != null) {
        paymentData["product_id"] = this.product.sku;
      } else {
        this.$toast.error(this.$t("payermax.selected_product_not_found"));
        return;
      }
      if (savedCardId != null) {
        paymentData["card_id"] = savedCardId;
      } else {
        paymentData["save_card"] = saveCardPermission;
      }
      try {
        const trustpayPaymentResponse = await this.trustPayPayment(paymentData);
        // Generate new UUID for next attempt on success or error from server.
        generatePaymentUUID();

        if (trustpayPaymentResponse.error) {
          // Error response from server
          logger.debugError(
            "trustpayPayment error",
            trustpayPaymentResponse.error
          );
          this.$toast.error(
            trustpayPaymentResponse.error.message ||
              this.$t("errors.general_error")
          );
          return false;
        } else if (trustpayPaymentResponse.redirect_url === null) {
          let payment_status = PaymentOperationStatus.PENDING;
          if (trustpayPaymentResponse.status) {
            if (trustpayPaymentResponse.status === "Rejected") {
              payment_status = PaymentOperationStatus.FAILED;
            }
          }

          this.$router.push({
            path: "/payermax/payment-complete",
            query: {
              status: payment_status,
              payment_id: trustpayPaymentResponse.payment_request_id,
            },
          });
          return true;
        } else if (trustpayPaymentResponse.redirect_url) {
          // Success response from server
          if (this.isIOS) {
            this.$hybridapi("showOnlyNavBackButton");
            this.$hybridapi("updateTopBar", "show", 255, 255, 255);
            this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
          } else {
            this.$hybridapi("updatePageTitle", "");
            this.$hybridapi("updateTopBar", "hide", null, null, null);
            this.$hybridapi("hideBackButton", "hide");
          }
          window.location.href = trustpayPaymentResponse.redirect_url;
          return true;
        }
      } catch (error) {
        // Network error or server fail
        logger.debugError("trustpayPayment failed", error);
        this.$toast.error(this.$t("errors.general_error"));
        return false;
      }
    },
    calculateInitialCoinAmount(overridenCoinAmount, coinIncreasePercentage) {
      return Math.ceil(
        overridenCoinAmount * (100 / (coinIncreasePercentage + 100))
      );
    },
    showTopBar() {
      this.$hybridapi("updateTopBar", "show", 255, 255, 255);
      this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
      this.$hybridapi("showOnlyNavBackButton");
    },
    hideTopBar() {
      this.$hybridapi("updatePageTitle", "");
      this.$hybridapi("updateTopBar", "hide", null, null, null);
      this.$hybridapi("hideBackButton", "hide");
    },
    async onDeleteCard(cardToDelete) {
      const data = {
        provider: this.selectedPayment.payment_provider,
        card_id: cardToDelete.card_id,
      };

      try {
        this.savedCardLoading = true;
        await this.deleteSavedCard(data);
        this.savedCardsData.cards = this.savedCardsData.cards.filter(
          (card) => card !== cardToDelete
        );
      } catch (err) {
        // Network error or server fail
        logger.debugError("deleteCard failed", err);
        this.$toast.error(this.$t("errors.general_error"));
      } finally {
        this.savedCardLoading = false;
      }
    },
    async onSavedCardPay(selectedCard) {
      const selectedCardId = selectedCard != null ? selectedCard.card_id : null;
      if (this.selectedPayment.payment_provider === PaymentProvider.PAYERMAX) {
        this.savedCardLoading = true;
        const isRedirecting = await this.payWithPayermax(
          this.selectedPayment,
          selectedCardId
        );
        if (!isRedirecting) {
          this.savedCardLoading = false;
        }
        return;
      }

      if (this.selectedPayment.payment_provider === PaymentProvider.TRUSTPAY) {
        this.savedCardLoading = true;
        const isRedirecting = await this.payWithTrustpay(selectedCardId, false);
        if (!isRedirecting) {
          this.savedCardLoading = false;
        }
        return;
      }

      throw Error(
        `Provider ${this.selectedPayment.payment_provider} not implemented`
      );
    },
    async onAddCard(savePermission) {
      console.log("here");
      if (this.selectedPayment.payment_provider === PaymentProvider.TRUSTPAY) {
        this.savedCardLoading = true;
        const isRedirecting = await this.payWithTrustpay(null, savePermission);
        if (!isRedirecting) {
          this.savedCardLoading = false;
        }
        return;
      }

      if (this.selectedPayment.payment_provider === PaymentProvider.PAYERMAX) {
        this.savedCardLoading = true;
        const isRedirecting = await this.payWithPayermax(this.selectedPayment);
        if (!isRedirecting) {
          this.savedCardLoading = false;
        }
        return;
      }

      throw Error(
        `Provider ${this.selectedPayment.payment_provider} not implemented`
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/components/Payment/Payermax/PaymentMethodBottomSheet.scss";
</style>
