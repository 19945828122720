<template>
  <div>
    <vue-bottom-sheet-vue2
      custom-class="saved-cards-bottom-sheet"
      ref="savedCardsBottomSheet"
      :z-index="1001"
      :can-swipe="canSwipe"
    >
      <template #header>
        <div class="header">
          <div class="header-text">
            {{ $t("payment.purchase_coins") }}
          </div>
          <div class="coin-amount-container">
            <div class="coin-icon">
              <img src="@/assets/img/payment/coin-icon.svg" />
            </div>
            <div class="coin-amount">{{ data.coinAmount }}</div>
          </div>
        </div>
      </template>
      <template #default>
        <div class="main-container" id="main-container" @scroll="handleScroll">
          <div v-if="data.cards.length > 0" class="saved-card-header">
            {{ $t("payment.saved_cards") }}
          </div>
          <div class="card-items">
            <div
              class="card-item"
              v-for="cardItem in data.cards"
              :key="cardItem.card_id"
              @click="selectedCard = cardItem"
            >
              <CardItem
                :card-item="cardItem"
                :is-active="cardItem === selectedCard"
                @delete="cardPendingToDelete = cardItem"
              />
            </div>
            <div
              v-if="showAddCard"
              class="add-new-card-container"
              @click="$emit('add-card', saveCardPermission)"
            >
              <div class="add-new-card-inner-container">
                <div class="c-card-icon">
                  <img
                    :src="require(`@/assets/img/trustpay/card-icon.svg`)"
                    alt="Card Icon"
                  />
                </div>
                <div class="add-card-text">
                  {{ $t("payment.add_card") }}
                </div>
              </div>
            </div>
            <div
              v-if="showSaveCardPermission"
              class="card-save-permission-container"
            >
              <div class="round">
                <input
                  v-model="saveCardPermission"
                  type="checkbox"
                  checked
                  id="checkbox"
                />
                <label for="checkbox"></label>
              </div>
              <div class="permission-text">
                {{ $t("payment.card_saving_agreement") }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="pay-button-container">
          <div class="pay-button" @click="$emit('pay', selectedCard)">
            <div>
              {{ data.currency }}
              {{ data.price }}
              {{ $t("labels.do_payment") }}
            </div>
          </div>
        </div>
      </template>
    </vue-bottom-sheet-vue2>
    <DeleteCardDialog
      :is-open="cardPendingToDelete != null"
      @close="cardPendingToDelete = null"
      @confirm="onDeleteCard"
    />
    <v-overlay
      :style="{ 'z-index': 1002 }"
      color="#fff"
      :value="showFullPageLoading"
    >
      <v-progress-circular
        color="#333"
        :size="50"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import CardItem from "./CardItem.vue";
import DeleteCardDialog from "./DeleteCardDialog.vue";

export default {
  name: "SavedCardsBottomSheet",
  props: {
    data: Object,
    showAddCard: Boolean,
    showSaveCardPermission: Boolean,
    showFullPageLoading: Boolean,
  },
  components: {
    DeleteCardDialog,
    CardItem,
    VueBottomSheetVue2,
  },
  data() {
    return {
      selectedCard: null,
      cardPendingToDelete: null,
      saveCardPermission: true,
      canSwipe: true,
    };
  },
  created() {
    if (this.data.cards.length > 0) {
      this.selectedCard = this.data.cards[0];
    }
  },
  mounted() {
    document
      .getElementById("main-container")
      .addEventListener("touchend", (e) => {
        this.handleScrollend();
      });
  },
  methods: {
    handleScrollend: function () {
      this.canSwipe = true;
    },
    handleScroll: function (el) {
      this.canSwipe = false;
    },
    open() {
      this.$refs.savedCardsBottomSheet.open();
    },
    onDeleteCard() {
      this.$emit("delete-card", this.cardPendingToDelete);
      this.cardPendingToDelete = null;
      if (this.data.cards.length > 0) {
        this.selectedCard = this.data.cards[0];
      } else {
        this.selectedCard = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/components/Payment/SavedCard/SavedCardsBottomSheet.scss";
</style>
