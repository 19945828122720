<template>
  <div
    :class="[
      `card-container`,
      {
        'selected-card-container': isActive,
      },
    ]"
  >
    <div class="card-inner-container">
      <div class="card-details">
        <div class="masked-pan">{{ cardItem.masked_card_number }}</div>
        <div v-if="cardItem.expiry" class="expiry-date">
          {{ cardItem.expiry.expiry_month }} / {{ cardItem.expiry.expiry_year }}
        </div>
      </div>
      <div class="delete-icon-container" @click="$emit('delete')">
        <img
          :src="require(`@/assets/img/trustpay/trash-icon.svg`)"
          alt="trash-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardItem",
  props: ["cardItem", "isActive"],
};
</script>

<style scoped lang="scss">
@import "@/components/Payment/SavedCard/CardItem.scss";
</style>
