<template>
  <div
    :class="[
      `product-container`,
      {
        'product-container-hot': product.tag === ProductTags.BEST_TAG,
        'product-container-discount': product.tag === ProductTags.DISCOUNT_TAG,
      },
    ]"
    @click="handleClick"
  >
    <div class="product-inner-container">
      <div class="coin-info">
        <div class="coin-icon">
          <img :src="require(`@/assets/img/coin.svg`)" alt="coin" />
        </div>
        <div class="coin-amount">
          <div class="amount">{{ productInfo.coin_amount }}</div>
          <div v-if="product.tag === ProductTags.BEST_TAG" class="hot-badge">
            <div class="hot-img">
              <img
                :src="require(`@/assets/img/fire-icon-purchase.svg`)"
                alt="fire-icon"
              />
            </div>
            <div class="hot-text">Hot</div>
          </div>
          <div
            v-if="product.tag === ProductTags.DISCOUNT_TAG"
            class="discount-badge"
          >
            <div
              v-if="
                product.payment_types[1].payment_price.coin_increase_percentage
              "
              class="discount-text"
            >
              +%
              {{
                product.payment_types[1].payment_price.coin_increase_percentage
              }}
            </div>
            <div v-else class="discount-text">
              % {{ product.payment_types[1].payment_price.discount }}
              {{ $t("labels.discount") }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          v-if="
            productInfo.original_price &&
            productInfo.price !== productInfo.original_price
          "
          class="original-price"
        >
          {{ productInfo.currency }}
          {{ productInfo.original_price }}
        </div>
        <div
          :class="[
            'coin-price',
            {
              'coin-price-hot': product.tag === ProductTags.BEST_TAG,
              'coin-price-discount': product.tag === ProductTags.DISCOUNT_TAG,
            },
          ]"
        >
          {{ productInfo.currency }}
          {{ productInfo.price }}
        </div>
      </div>
    </div>
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { PaymentProvider, ProductTags } from "@/helper/enums";

const CARD_PROVIDERS = new Set([
  PaymentProvider.RAPYD,
  PaymentProvider.PAYERMAX,
  PaymentProvider.TRUSTPAY,
]);

export default {
  name: "CoinProduct",
  props: ["product"],
  data() {
    return {
      ProductTags,
    };
  },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      currencies: (state) => state.client.currencies,
    }),
    productInfo: function () {
      let credit_card_index = null;
      credit_card_index = this.product.payment_types.findIndex(
        (paymentType) => {
          return CARD_PROVIDERS.has(paymentType.payment_provider);
        }
      );
      if (
        this.product.payment_types.length > 1 &&
        credit_card_index !== -1 &&
        CARD_PROVIDERS.has(
          this.product.payment_types[credit_card_index].payment_provider
        ) &&
        this.product.payment_types[credit_card_index].is_available
      ) {
        let coin_amount;
        if (
          this.product.payment_types[credit_card_index].payment_price
            .overriden_coin_amount
        ) {
          coin_amount =
            this.product.payment_types[credit_card_index].payment_price
              .overriden_coin_amount;
        } else {
          coin_amount = this.product.amount;
        }
        return {
          price:
            this.product.payment_types[credit_card_index].payment_price.price,
          original_price:
            this.product.payment_types[credit_card_index].payment_price
              .original_price,
          currency:
            this.currencies[
              this.product.payment_types[
                credit_card_index
              ].payment_price.currency.toUpperCase()
            ],
          coin_amount: coin_amount,
        };
      } else {
        if (
          this.product.payment_types[0].payment_price &&
          this.product.payment_types[0].payment_price !== null
        ) {
          return {
            price: this.product.payment_types[0].payment_price.price,
            original_price:
              this.product.payment_types[0].payment_price.original_price,
            currency:
              this.currencies[
                this.product.payment_types[0].payment_price.currency.toUpperCase()
              ],
            coin_amount: this.product.amount,
          };
        } else {
          return {
            price: null,
            original_price: null,
            currency: null,
            coin_amount: this.product.amount,
          };
        }
      }
    },
  },
  methods: {
    handleClick() {
      this.$emit("productSelected", this.product);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/components/Payment/Payermax/CoinProduct.scss";
</style>
