<template>
  <div
    :class="{ 'hybrid-container': isHybridPage }"
    :style="{ height: '100vh' }"
  >
    <div class="status-container">
      <template v-if="status === PaymentOperationStatus.PENDING">
        <div class="status warning">
          <i class="fas fa-clock"></i>
        </div>
        <div>{{ $t("checkout.payment_pending") }}</div>
      </template>
      <template v-else-if="status === PaymentOperationStatus.SUCCESS">
        <div class="status success">
          <i class="fas fa-check"></i>
        </div>
        <div>{{ $t("checkout.payment_success") }}</div>
      </template>
      <template v-else-if="status === PaymentOperationStatus.FAILED">
        <div class="status error">
          <i class="fas fa-times"></i>
        </div>
        <div>{{ $t("checkout.payment_failed") }}</div>
      </template>
      <div class="redirect">{{ $t("labels.redirecting") }}</div>
    </div>
  </div>
</template>

<script>
"use strict";

import { mapState, mapActions } from "vuex";
import { Logger } from "@/logging";
import {
  PaymentOperationStatus,
  PaymentProvider,
  PaymentStatus,
  PayermaxOperationStatus,
} from "@/helper/enums";

const logger = new Logger("Payermax/PaymentComplete");

export default {
  name: "PaymentComplete",
  data() {
    return {
      status: PaymentOperationStatus.SUCCESS,
      timer: null,
      lastTimer: null,
      PaymentOperationStatus,
    };
  },
  computed: {
    ...mapState({ isHybridPage: (state) => state.client.isHybridPage }),
  },
  methods: {
    ...mapActions("payment", [
      "paymentStatusWebsocket",
      "postHybridCloseChecks",
      "retrievePaymentStatus",
    ]),
    onHybridClose() {
      logger.debug("Close payment complete page bridge event sending...");
      this.$hybridapi("close", "close");

      if (this.isHybridPage) this.postHybridCloseChecks();
    },
    /**
     * @param {number} [timeout=5000]
     */
    setTimer(timeout = 5000) {
      if (this.timer == null) {
        this.timer = setTimeout(() => this.onHybridClose("close"), timeout);
      } else {
        logger.debug("There is an active timer skip setting timeout");
      }
    },
    clearTimer() {
      if (this.timer != null) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      if (this.lastTimer != null) {
        clearTimeout(this.lastTimer);
        this.lastTimer = null;
      }
    },
    /**
     * @param {number} paymentId
     */
    async onPaymentStatusRetrieve(paymentId, paymentProvider) {
      if (
        this.status == PaymentOperationStatus.SUCCESS ||
        this.status == PaymentOperationStatus.FAILED
      ) {
        this.setTimer();
        return;
      }

      const onWebsocketConnect = async (forceClose = null) => {
        const payload = {
          payment_provider: paymentProvider,
          payment_id: paymentId,
        };

        try {
          /** @type {{status: PaymentStatus}} */
          const response = await this.retrievePaymentStatus(payload);

          if (response.status === PaymentStatus.SUCCESS) {
            this.status = PaymentOperationStatus.SUCCESS;
            this.setTimer();
          } else if (response.status === PaymentStatus.FAILED) {
            this.status = PaymentOperationStatus.FAILED;
            this.setTimer();
          } else if (response.status === PaymentOperationStatus.PENDING) {
            this.status = PaymentOperationStatus.PENDING;
            if (forceClose) {
              this.onHybridClose();
            }
          } else {
            logger.warn(
              `Unexpected ${paymentProvider} payment operation status`,
              response
            );
            if (forceClose) {
              this.onHybridClose();
            }
          }
        } catch (error) {
          logger.error("Error getting payment operation status.", error);
          this.status = PaymentOperationStatus.FAILED;
        }
      };

      this.lastTimer = setTimeout(() => {
        onWebsocketConnect(true);
      }, 10000);

      this.paymentStatusWebsocket({
        url: this.$hybrid_websocket_url,
        onConnect: onWebsocketConnect,
      }).then((event) => {
        if (
          event.data.provider === paymentProvider &&
          paymentId === event.data.payment_id
        ) {
          if (event.data.status === PaymentStatus.SUCCESS) {
            this.status = PaymentOperationStatus.SUCCESS;
            this.setTimer();
          } else if (event.data.status === PaymentStatus.FAILED) {
            this.status = PaymentOperationStatus.FAILED;
            this.setTimer();
          }
        }
      });
    },
  },
  beforeMount() {
    /** @type {PaymentOperationStatus} */
    let status;

    if (PayermaxOperationStatus[this.$route.query.status] != null) {
      status = PayermaxOperationStatus[this.$route.query.status];
    } else {
      status = parseInt(this.$route.query.status, 10);
    }

    if (isNaN(status)) {
      logger.debug("Query status is misgiven", this.$route.query.status);
      this.status = PaymentOperationStatus.FAILED;
    } else {
      this.status = status;
    }

    const trustPayPaymentRequestId = parseInt(this.$route.query.payment_id, 10);
    const razerPaymentId = this.$route.query.razer_payment_id;

    if (!isNaN(trustPayPaymentRequestId)) {
      this.onPaymentStatusRetrieve(
        trustPayPaymentRequestId,
        PaymentProvider.TRUSTPAY
      );
    } else if (razerPaymentId !== null) {
      this.onPaymentStatusRetrieve(razerPaymentId, PaymentProvider.RAZER);
    } else {
      this.setTimer();
    }
  },
  mounted() {
    window["backButtonTapped"] = () => {
      this.onHybridClose("close");
      return "close";
    };
    this.$hybridapi("showOnlyNavBackButton");
    this.$hybridapi("updateTopBar", "show", 255, 255, 255);
    this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
    this.$hybridapi("updateWindowSize", "medium");
  },
  destroyed() {
    this.clearTimer();
  },
};
</script>
