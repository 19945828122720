<template>
  <div>
    <nav
      class="mobile-nav"
      v-if="$route.path !== '/messages' && !$route.path.includes('/profile')"
    >
      <v-container :fluid="true">
        <v-row>
          <v-col class="mobile-nav-menu-wrapper" lg="3" md="3" sm="6" xs="6">
            <div class="menu-logo-container">
              <router-link to="/">
                <img
                  :src="require(`@/assets/img/mobile-logo_${brandName}.svg`)"
                  v-bind:alt="brandTitle + ' App'"
                  v-bind:title="brandTitle + ' App'"
                  class="logo"
                />
              </router-link>
            </div>
            <div v-if="settings !== null" class="item">
              <a>
                <v-btn
                  v-if="settings !== null"
                  elevation="2"
                  large
                  class="coin-button"
                  @click="checkShowCoinModal()"
                >
                  <img
                    class="coin-img"
                    src="@/assets/img/coin.svg"
                    :alt="$t('general.coin_icon')"
                  />
                  <span class="coin-count-span"
                    >{{ showThousund(userCoinInfo.coins, 1) }}
                  </span>
                  <span class="buy-coin">{{ $t("general.buy_coin") }}</span>
                </v-btn>
              </a>
            </div>
            <div v-if="$store.getters['client/isLoggedIn']">
              <a
                @click="setShowJoinNow(!showJoinNow)"
                :class="showJoinNow ? 'active' : ''"
              >
                <div class="user-img-container">
                  <v-badge
                    v-if="this.unseenNotificationCount > 0"
                    offset-x="11"
                    offset-y="11"
                    dot
                    overlap
                  >
                    <UserImageCircle
                      :user="ownProfile && ownProfile.user"
                      size="40px"
                    />
                  </v-badge>
                  <UserImageCircle
                    v-else
                    :user="ownProfile && ownProfile.user"
                    size="40px"
                  />
                </div>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </nav>
    <div class="main-nav">
      <ul class="main-top-menu">
        <li class="logo">
          <router-link to="/">
            <img
              v-bind:src="require(`@/assets/img/${brandName}.png`)"
              v-bind:srcset="
                require(`@/assets/img/${brandName}@2x.png`) + ' 2x'
              "
              v-bind:alt="brandTitle"
              v-bind:title="brandTitle"
            />
          </router-link>
        </li>
        <li>
          <router-link
            to="/"
            :class="$route.path === '/' && !showJoinNow ? 'active' : ''"
          >
            <div>
              <img
                src="@/assets/img/white-live-logo.svg"
                alt="Live"
                title="Live"
              />
            </div>
            <div>
              {{ $t("menu.live") }}
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :class="
              $route.path === `/followings-stream/` &&
              !showJoinNow &&
              !showBuyCoinsModal
                ? 'active'
                : ''
            "
            :to="`/followings-stream/`"
          >
            <div>
              <i class="fas fa-users"></i>
            </div>
            <div>
              {{ $t("menu.followings") }}
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :class="
              $route.path === '/leaderboard' &&
              !showBuyCoinsModal &&
              !showJoinNow
                ? 'active'
                : ''
            "
            :to="'/leaderboard'"
          >
            <div>
              <i class="fas fa-crown"></i>
            </div>
            <div>
              {{ $t("menu.leader_board") }}
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            to=""
            @click.native="messageClickHandler"
            :class="
              $route.path === `/messages` && !showBuyCoinsModal && !showJoinNow
                ? 'active'
                : ''
            "
          >
            <div>
              <i class="fas fa-comment"></i>
            </div>
            <div>
              {{ $t("menu.messages") }}
            </div>
          </router-link>
        </li>
        <li>
          <a
            :class="showBuyCoinsModal ? 'active-buy-coin' : ''"
            @click="checkShowCoinModal()"
          >
            <img
              src="@/assets/img/buy-coin-star.svg"
              alt="Buy Coin"
              title="Buy Coin"
            />
            <div>
              {{ $t("menu.purchase") }}
            </div>
          </a>
        </li>
      </ul>
      <ul class="main-bottom-menu">
        <li v-if="!isLoggedIn">
          <a
            @click="setShowJoinNow(!showJoinNow)"
            :class="showJoinNow ? 'active' : ''"
          >
            <div>
              <UserImageCircle
                :user="ownProfile && ownProfile.user"
                size="40px"
              />
            </div>
            <div>
              {{ $t("menu.join_now") }}
            </div>
          </a>
        </li>
        <li v-if="$store.getters['client/isLoggedIn']">
          <a
            @click="setShowJoinNow(!showJoinNow)"
            :class="showJoinNow ? 'active' : ''"
          >
            <div>
              <v-badge
                v-if="this.unseenNotificationCount > 0"
                offset-x="11"
                offset-y="11"
                dot
                overlap
              >
                <UserImageCircle
                  :user="ownProfile && ownProfile.user"
                  size="40px"
                />
              </v-badge>
              <UserImageCircle
                v-else
                :user="ownProfile && ownProfile.user"
                size="40px"
              />
            </div>
            <div
              v-if="ownProfile && ownProfile.user !== null"
              class="menu-user-name"
            >
              {{ ownProfile.user.name }}
            </div>
          </a>
        </li>
        <li class="divider">
          <div></div>
        </li>
        <li>
          <router-link
            to="/settings"
            :class="$route.path === '/settings' && !showJoinNow ? 'active' : ''"
          >
            <img
              src="@/assets/img/settings.svg"
              alt="Settings"
              title="Settings"
              class="settings"
            />
          </router-link>
        </li>
      </ul>
    </div>

    <transition name="fade">
      <div
        class="join-now-overlay"
        v-if="showJoinNow"
        @click="setShowJoinNow(!showJoinNow)"
      ></div
    ></transition>
    <transition>
      <div v-if="showJoinNow" class="join-now-modal">
        <template v-if="!isLoggedIn">
          <div class="header">
            {{ $t("login_page.header", { brandName: brandName }) }}
          </div>
          <div class="sub-header">{{ $t("login_page.sub_header") }}</div>
          <div>
            <button class="button blue-button" @click="checkShowLoginModal()">
              {{ $t("menu.join_now") }}
            </button>
          </div>
          <div class="divider"></div>
          <ul>
            <li>
              <router-link to="/privacy-policy">{{
                $t("menu.privacy_policy")
              }}</router-link>
            </li>
            <li>
              <router-link to="/terms-of-service">{{
                $t("menu.terms_of_service")
              }}</router-link>
            </li>
            <li>
              <router-link to="/contact">{{ $t("menu.contact") }}</router-link>
            </li>
          </ul>
        </template>
        <template v-else-if="ownProfile && ownProfile.user !== null">
          <router-link
            v-if="ownProfile"
            :to="generateBase64Url('/profile/', ownProfile.user.user_id)"
          >
            <UserImageCircle :user="ownProfile && ownProfile.user" size="70px"
          /></router-link>
          <div class="user-content-container">
            <div
              v-if="ownProfile && ownProfile.user.leveling_progress"
              v-bind:title="$t('profile.user_level')"
            >
              <level-badge
                :user="ownProfile && ownProfile.user"
                :fontSize="'14'"
              ></level-badge>
            </div>
            <div class="user-name text-ellipsis">
              {{ ownProfile.user.name }}
            </div>

            <div class="vip-badge">
              <img :src="vipBadgeSrc" class="vip-badge" v-if="vipBadgeSrc" />
            </div>
          </div>
          <div class="divider"></div>
          <ul>
            <li>
              <router-link
                :to="generateBase64Url('/profile/', ownProfile.user.user_id)"
              >
                {{ $t("menu.go_profile") }}
              </router-link>
            </li>
            <li>
              <router-link to="/notifications/">
                {{ $t("menu.notifications_menu") }}
              </router-link>
              <span
                v-if="this.unseenNotificationCount > 0"
                class="user-modal-notif-user"
              >
                {{ this.unseenNotificationCount }}
              </span>
            </li>
            <li>
              <a href="javascript:;" @click="logout()"
                ><i class="fas fa-sign-out-alt"></i> {{ $t("menu.logout") }}</a
              >
            </li>
          </ul>
          <div class="divider"></div>
          <ul>
            <li>
              <router-link to="/privacy-policy">{{
                $t("menu.privacy_policy")
              }}</router-link>
            </li>
            <li>
              <router-link to="/terms-of-service">{{
                $t("menu.terms_of_service")
              }}</router-link>
            </li>
            <li>
              <router-link to="/contact">{{ $t("menu.contact") }}</router-link>
            </li>
          </ul>
        </template>
      </div>
    </transition>

    <div
      class="mobile-bottom-nav"
      v-if="
        !$route.path.includes('/stream') &&
        !$route.path.includes('/profile') &&
        !mobileChatActive
      "
    >
      <div class="item">
        <router-link
          to="/"
          :class="
            $route.path === '/' && !showBuyCoinsModal && !showJoinNow
              ? 'active'
              : ''
          "
        >
          <div class="icon-container">
            <img
              src="@/assets/img/white-live-logo.svg"
              alt="Live"
              title="Live"
            />
          </div>
          <div>
            {{ $t("menu.mobile_live") }}
          </div>
        </router-link>
      </div>
      <div class="item">
        <router-link
          :class="
            $route.path === `/followings-stream/` &&
            !showBuyCoinsModal &&
            !showJoinNow
              ? 'active'
              : ''
          "
          :to="`/followings-stream/`"
        >
          <div class="icon-container">
            <i class="fas fa-users"></i>
          </div>
          <div>
            {{ $t("menu.mobile_follow") }}
          </div>
        </router-link>
      </div>
      <div class="item">
        <router-link
          :class="
            $route.path === '/leaderboard/' && !showJoinNow ? 'active' : ''
          "
          :to="'/leaderboard/'"
        >
          <div class="icon-container">
            <img
              src="@/assets/img/leader-board-icon.svg"
              :alt="$t('general.leaderboard_icon')"
              title="Leaderboard"
            />
          </div>
          <div>
            {{ $t("menu.leader_board") }}
          </div>
        </router-link>
      </div>
      <div class="item">
        <a>
          <button class="icon-container" @click="checkShowCoinModal()">
            <img
              src="@/assets/img/buy-coin-blue-icon.svg"
              :alt="$t('general.buy_coin')"
            />
          </button>
          <div>
            {{ $t("menu.mobile_coins") }}
          </div>
        </a>
      </div>
      <div class="item">
        <router-link
          to=""
          @click.native="messageClickHandler"
          :class="
            $route.path === '/messages' && !showBuyCoinsModal && !showJoinNow
              ? 'active'
              : ''
          "
        >
          <div class="icon-container">
            <i class="fas fa-comment"></i>
          </div>
          <div>
            {{ $t("menu.mobile_chat") }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./Navigation.scss";
</style>

<script>
import { mapState, mapActions } from "vuex";
import bronzeVipImage from "@/assets/img/bronze-vip.svg";
import silverVipImage from "@/assets/img/silver-vip.svg";
import goldVipImage from "@/assets/img/gold-vip.svg";

import { generateBase64Url, showThousund } from "@/helper/index.js";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";

const imagesContext = require.context("@/assets/img/", false, /\.png$/);

export default {
  name: "Navigation",
  data() {
    return {
      vipBadgeSrc: null,
      bronzeVipImage: bronzeVipImage,
      silverVipImage: silverVipImage,
      goldVipImage: goldVipImage,
    };
  },
  components: {
    UserImageCircle,
    LevelBadge,
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => !!state.client.authToken,
      ownProfile: (state) => state.client.ownProfile,
      showBuyCoinsModal: (state) => state.client.showBuyCoinsModal,
      watchingStream: (state) => state.live.watchingStream,
      showJoinNow: (state) => state.client.showJoinNow,
      brandName: (state) => state.brandName,
      settings: (state) => state.client.settings,
      unseenNotificationCount: (state) => state.client.unseenNotificationCount,
      brandTitle: (state) => state.brandTitle,
      mobileChatActive: (state) => state.client.mobileChatActive,
      selectedUser: (state) => state.live.selectedUser,
      conversationId: (state) => state.client.conversationId,
      userCoinInfo: (state) => state.client.userCoinInfo,
    }),
    onboarding() {
      return this.$route.name == "OnboardingView";
    },
  },
  updated() {
    switch (
      this.ownProfile &&
      this.ownProfile.user.vip_state &&
      this.ownProfile.user.vip_state.current_state
    ) {
      case 1:
        this.vipBadgeSrc = bronzeVipImage;
        break;
      case 2:
        this.vipBadgeSrc = silverVipImage;
        break;
      case 3:
        this.vipBadgeSrc = goldVipImage;
        break;
      default:
        this.vipBadgeSrc = null;
    }
  },
  methods: {
    ...mapActions("client", [
      "logout",
      "setShowBuyCoinsModal",
      "setShowLoginModal",
      "setShowJoinNow",
      "getUserDirectMessageHistory",
      "setConversationIdProfile",
    ]),
    messageClickHandler() {
      this.setConversationIdProfile(null);
      this.$router.push("/messages");
    },
    checkShowLoginModal() {
      if (this.$route.path.includes("/login")) {
        this.setShowLoginModal(false);
        this.setShowJoinNow(false);
      } else {
        this.setShowLoginModal(true);
      }
    },
    checkShowCoinModal() {
      if (this.$route.path.includes("/login")) {
        this.setShowBuyCoinsModal(false);
      } else {
        this.setShowBuyCoinsModal({
          showBuyCoinsModal: true,
          buyCoinBackground: "black-opac-bg",
          pageName: "full_screen",
        });
      }
    },
    generateBase64Url,
    showThousund,
    toggleMenu() {
      this.$store.dispatch("setMenuOpened", !this.$store.getters.isMenuOpened);
    },
    getImgUrl(imgName) {
      return imagesContext("./" + imgName + ".png");
    },
  },
};
</script>
