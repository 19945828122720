<template>
  <div
    ref="fullProfileRef"
    class="full-profile-wrapper"
    v-if="!!this.userProfile"
  >
    <edit-profile v-if="showEditProfile === true" />
    <div class="full-profile-slider-wrapper" id="fullProfileSliderBox">
      <div class="carousel-container full-screen">
        <v-carousel
          ref="carouselRef"
          v-model="currentIndex"
          :dark="true"
          :height="carouselHeight"
        >
          <template
            v-if="
              this.userProfile.profile_images &&
              this.userProfile.profile_images.length > 0
            "
          >
            <v-carousel-item
              v-for="(profileImg, index) in this.userProfile.profile_images"
              :key="`${index}`"
              :src="profileImg.thumbnail_url"
            ></v-carousel-item>
          </template>
          <template v-else>
            <v-carousel-item
              src="@/assets/img/cover-default.gif"
            ></v-carousel-item>
          </template>
        </v-carousel>

        <div class="back-button-container">
          <div class="full-screen-svg" @click="fullScreen">
            <img src="@/assets/img/full-screen.svg" alt="full-screen" />
          </div>
          <div class="back-button-arrow-svg" @click="goBack">
            <img
              src="@/assets/img/back-button-arrow.svg"
              :alt="$t('general.back_button')"
            />
          </div>
        </div>
        <v-btn
          v-if="this.userProfile.user_id === this.ownProfile.user.user_id"
          class="edit-profile-container"
          @click="editProfile(true)"
          depressed
        >
          <img
            class="edit-profile-svg"
            src="@/assets/img/edit-profile.svg"
            :alt="$t('general.edit_profile')"
          />
          <span class="edit-profile-title">
            {{ $t("full_profile_page.edit_user_profile") }}
          </span>
        </v-btn>
        <div class="mobile-share-report-container">
          <div class="report-mobile-svg">
            <button
              @click="showDownloadApp"
              v-if="userProfile.user_id !== ownProfile.user.user_id"
            >
              <img
                src="@/assets/img/full-profile-report.svg"
                :alt="$t('general.report_button')"
              />
            </button>
          </div>
          <div class="share-mobile-svg">
            <button
              @click="showDownloadApp"
              v-if="userProfile.user_id !== ownProfile.user.user_id"
            >
              <img
                src="@/assets/img/share-icon.svg"
                :alt="$t('general.share_button')"
              />
            </button>
          </div>
        </div>
        <div class="place-container">
          <img
            src="@/assets/img/full-profile-place-icon.svg"
            alt="place icon"
            class="place-icon"
          />
          <span class="place-content">
            {{ displayCountry(this.userProfile.country) }}
          </span>
        </div>
        <div class="report-share-container-items">
          <div class="share-container">
            <button @click="showDownloadApp">
              <img src="@/assets/img/share-icon-profile.svg" alt="share icon" />
            </button>
          </div>
          <div
            class="report-container"
            v-if="userProfile.user_id !== ownProfile.user.user_id"
          >
            <span class="report-svg">
              <button @click="showDownloadApp">
                <img src="@/assets/img/report.svg" alt="report svg" />
              </button>
            </span>
          </div>
          <router-link
            :to="
              '/stream/' +
              getCryptedString(
                this.userProfile.livestream_id,
                this.userProfile.user_id
              )
            "
            v-if="this.userProfile.livestream_id !== null"
          >
            <span
              class="live-status-mobile"
              @click="setLiveStreamSource('profile_live_button')"
            >
              <img src="@/assets/img/live-status-icon-animation.gif" />
              {{ $t("labels.live") }}
            </span>
          </router-link>
        </div>
        <div class="img-container">
          <div class="profile-img-container">
            <user-image-circle
              :user="this.userProfile"
              size="150px"
              border=""
            />
            <router-link
              :to="
                '/stream/' +
                getCryptedString(
                  this.userProfile.livestream_id,
                  this.userProfile.user_id
                )
              "
              v-if="this.userProfile.livestream_id !== null"
            >
              <span
                class="live-status"
                @click="setLiveStreamSource('profile_live_button')"
              >
                <img src="@/assets/img/live-status-icon-animation.gif" />
                {{ $t("labels.live") }}
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="full-profile-content-wrapper">
      <div class="full-profile-content-container">
        <div class="follow-button-container">
          <div class="user-name-container">
            <level-badge :user="this.userProfile"></level-badge>
            <span class="user-name" :title="this.userProfile.name">
              {{ this.userProfile.name }}
            </span>
            <img
              v-if="returnVipString(this.userProfile.vip_state)"
              :src="
                require(`@/assets/img/${returnVipString(
                  this.userProfile.vip_state
                )}-vip.svg`)
              "
              class="vip-badge"
            />
          </div>
          <v-btn
            v-if="this.userProfile.is_followed === false"
            class="follow-icon-svg"
            :loading="isFollowLoading"
            :disabled="isFollowLoading"
            @click="clickFollowUser()"
            rounded
            small
          >
            <img
              :src="require(`@/assets/img/follow-icon_${brandName}.svg`)"
              alt="follow icon"
            />
          </v-btn>
          <v-btn
            v-if="this.userProfile.is_followed === true"
            class="follow-icon-svg"
            :loading="isFollowLoading"
            :disabled="isFollowLoading"
            @click="clickUnfollowUser()"
            rounded
            small
          >
            <img src="@/assets/img/unfollow-icon.svg" alt="follow icon" />
          </v-btn>
        </div>

        <div class="user-id-container">
          <span
            class="user-id"
            v-if="ownProfile.user.user_id === userProfile.user_id"
            >ID: {{ this.userProfile.shared_id }}
          </span>
        </div>

        <div class="counter-columns">
          <div class="follower-count-column">
            <router-link
              class="router-link"
              :to="generateBase64Url('/followers/', this.userProfile.user_id)"
            >
              <span class="counter">{{
                showThousund(this.userProfile.follower_count, 1)
              }}</span>
              <span class="counter-title">{{
                $t("full_profile_page.follower")
              }}</span>
            </router-link>
          </div>

          <div class="following-count-column">
            <router-link
              class="router-link"
              :to="generateBase64Url('/followings/', this.userProfile.user_id)"
            >
              <span class="counter">{{
                showThousund(this.userProfile.following_count, 1)
              }}</span>
              <span class="counter-title">{{
                $t("full_profile_page.following")
              }}</span>
            </router-link>
          </div>

          <div class="diamond-count-column">
            <div class="counter padd">
              <span>{{ showThousund(this.userProfile.diamonds) }}</span>
            </div>
            <div class="counter-title">
              <div class="float-left">
                <img class="diamond-img" src="@/assets/img/gems.svg" />
              </div>
              <span class="margin-b2">{{
                $t("full_profile_page.diamonds")
              }}</span>
            </div>
          </div>

          <div class="send-count-column">
            <div class="counter padd">
              <span>{{ showThousund(this.userProfile.coins) }}</span>
            </div>
            <div class="counter-title">
              <div class="send-coin-img">
                <img src="@/assets/img/coin-half-profile.svg" alt="" />
              </div>
              <span class="margin-b2">{{ $t("full_profile_page.sent") }}</span>
            </div>
          </div>
        </div>
        <div v-if="this.userProfile.bio" class="bio-content">
          <span class="bio-content-title"
            >{{ $t("full_profile_page.bio") }}:</span
          >
          <span class="bio-title"> {{ this.userProfile.bio }}... </span>
        </div>
        <div class="footer-button-columns">
          <v-btn
            v-if="userProfile.user_id !== ownProfile.user.user_id"
            @click="sendMessageClick"
            class="footer-button-message"
          >
            <img
              class="message-svg"
              :src="require(`@/assets/img/message-icon_${brandName}.svg`)"
              :alt="$t('general.send_message_button')"
            />
            <span>{{ $t("full_profile_page.message") }}</span>
          </v-btn>

          <v-btn
            v-if="this.userProfile.is_followed === false"
            class="footer-button-follow"
            :loading="isFollowLoading"
            @click="clickFollowUser()"
          >
            <img
              class="follow-svg"
              :src="require(`@/assets/img/follow-icon_${brandName}.svg`)"
              alt="follow svg"
            />
            <span>{{ $t("full_profile_page.follow") }}</span>
          </v-btn>
          <v-btn
            v-if="this.userProfile.is_followed === true"
            class="footer-button-unfollow"
            :loading="isFollowLoading"
            @click="clickUnfollowUser()"
          >
            <img
              class="unfollow-svg"
              src="@/assets/img/unfollow.svg"
              alt="follow svg"
            />
            <span>{{ $t("full_profile_page.unfollow") }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "FullProfile.scss";
</style>
<script>
import DownloadAppDialog from "@/components/DownloadAppDialog/DownloadAppDialog.vue";
import { mapState, mapActions } from "vuex";
import { displayCountry } from "@/helper/displaycountry.js";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import EditProfile from "@/components/Profiles/EditProfile/EditProfile.vue";
import {
  encrypt,
  decodeUrlParameter,
  generateBase64Url,
  showThousund,
  returnVipString,
} from "@/helper/index.js";
import { FollowPageEnums } from "@/helper/enums.js";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";

export default {
  name: "FullProfile",
  data() {
    return {
      currentIndex: 0,
      FollowPageEnums,
    };
  },
  components: {
    UserImageCircle,
    LevelBadge,
    EditProfile,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.live.selectedUser,
      ownProfile: (state) => state.client.ownProfile,
      isFollowLoading: (state) => state.client.isFollowLoading,
      showEditProfile: (state) => state.client.showEditProfile,
      brandName: (state) => state.brandName,
      userMessages: (state) => {
        if (
          state.client.userDirectMessageHistory &&
          state.client.userDirectMessageHistory.messages
        )
          return state.client.userDirectMessageHistory.messages;

        return [];
      },
    }),
    decodedProfileId() {
      return decodeUrlParameter(this.$route.params.profileId);
    },
    carouselHeight() {
      return window.innerHeight / 2;
    },
  },
  watch: {
    $route() {
      if (this.userProfile?.user_id !== this.decodedProfileId) {
        this.resetSelectedUser();
        this.getData();
      }
    },
  },
  mounted() {
    if (this.userProfile?.user_id !== this.$route.params.profileId) {
      this.resetSelectedUser();
      this.getData();
    }
  },
  methods: {
    ...mapActions("client", [
      "setShowFullProfile",
      "followUser",
      "unfollowUser",
      "getSelectedUser",
      "resetSelectedUser",
      "getUserMessageInfo",
      "editProfile",
      "getUserDirectMessageHistory",
      "setMobileChatActive",
      "setConversationIdProfile",
    ]),
    ...mapActions("live", ["setLiveStreamSource"]),
    displayCountry,
    decodeUrlParameter,
    generateBase64Url,
    showThousund,
    getData() {
      this.getSelectedUser({ user_id: this.decodedProfileId });
    },
    setCarouselHeight() {
      this.$refs.carouselRef.height = window.innerHeight / 2;
    },
    clickFollowUser() {
      this.followUser({
        user_id: this.userProfile.user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
      });
    },
    clickUnfollowUser() {
      this.unfollowUser({
        user_id: this.userProfile.user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
      });
    },
    showDownloadApp() {
      this.$buefy.modal.open({
        parent: this,
        component: DownloadAppDialog,
        "has-modal-card": true,
        trapFocus: true,
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    fullScreen() {
      this.$refs.carouselRef.height = "100%";
    },
    getCryptedString(streamId, userId) {
      let cryptObj = { streamId: streamId, userId: userId };
      return encrypt(cryptObj);
    },
    sendMessageClick() {
      if (this.isLoggedIn === false) {
        this.setShowLoginModal(true);
      } else {
        const user_id = this.userProfile.user_id;
        this.setConversationIdProfile(null);
        this.getUserMessageInfo({ user_id });
        this.$router.push("/messages");
      }
    },
    returnVipString,
  },
};
</script>
<style lang="scss">
@import "FullProfileSlider.scss";
</style>
