<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <div class="download-container">
        <div class="download-left">
          <img src="@/assets/img/download-app-left.svg" />
        </div>
        <div class="download-right">
          <div class="download-label">
            <div class="download-logo">
              <img src="@/assets/img/download-app-logo.svg" />
            </div>
            {{ $t("errors.download_app_message") }}
          </div>
          <div class="download-images">
            <a href="https://sprlv.link/web/store/google-play" target="_blank"
              ><img src="@/assets/img/download-on-google.png" />
            </a>
            <a href="https://sprlv.link/web/store/apple" target="_blank"
              ><img src="@/assets/img/download-on-apple.png" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
.modal-card {
  max-width: 480px;
  margin: 0 auto !important;
  padding: 10px;
  &-body {
    border-radius: 10px;
  }
}
.download-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 25px;
}
.download-left {
  width: 70%;
}
.download-right {
  padding: 0 20px;
}
.download-logo {
  margin-bottom: 20px;
}
.download-label {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: $primary;
}
.download-images {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    img {
      width: 150px;
      margin-right: 10px;
    }
  }
}
</style>
<script>
export default {
  name: "DownloadAppDialog",
};
</script>
